export enum BorrowerPlatformStateType {
  ApplicationIncomplete = "application_incomplete",
  ApplicationComplete = "application_complete",
  Underwriting = "underwriting",
  Scorecards = "scorecards",
  NoPlacements = "no_placements",
  PlacementSubmitted = "placement_submitted",
  NoOffers = "no_offers",
  OfferReceived = "offer_received",
  OfferAccepted = "offer_accepted",
  OfferStipsIncomplete = "offer_stips_incomplete",
  OfferStipsComplete = "offer_stips_complete",
  Checkout = "checkout",
  Funded = "funded",
  Dead = "dead"
}
